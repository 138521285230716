import React from 'react';

export default function Flame({ color }) {
  return(
    <svg width="21px" height="24px" viewBox="0 0 21 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>fire</title>
      <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Contact-Hob" transform="translate(-150.000000, -407.000000)" fill={color} fillRule="nonzero">
          <g id="gas" transform="translate(20.000000, 394.000000)">
            <g id="Group-2" transform="translate(0.000000, 13.000000)">
              <g id="fire" transform="translate(130.000000, 0.000000)">
                <path d="M19.16296,13.38168 C18.20216,11.98616 17.712,10.93904 17.58136,9.19672 C17.48352,7.89464 18.03088,6.39104 18.03088,6.39104 C17.35248,6.84288 17.34496,6.79448 16.89712,7.16456 C14.56616,9.09112 13.95712,11.0192 13.88584,11.26848 C13.88096,11.28616 13.87816,11.29632 13.87816,11.29632 L13.88584,11.26848 C13.88584,11.26848 15.70768,4.14112 10.63616,0.82216 C10.21528,0.5468 9.24448,0 9.24448,0 C9.24448,0 11.76248,6.17312 7.25416,9.83528 C6.3668,6.2136 1.68216,6.16904 1.68216,6.16904 C1.68216,6.16904 2.04216,7.43088 2.1,7.72936 C2.8592,11.66528 0.53224,12.9108 0.0596,16.63152 C0.11352,16.20448 0.09208,16.37448 0.05928,16.63384 C0.05912,16.63472 0.05896,16.63568 0.05896,16.63664 C0.05904,16.63616 0.05904,16.63584 0.05912,16.63536 C0.05896,16.63648 0.05888,16.63752 0.0588,16.63848 C-0.31008,19.55936 1.08176,21.8216 3.22064,23.348 C3.32048,22.31704 3.74312,21.29432 4.33048,20.44096 C4.9952,19.4756 5.3344,18.7512 5.4244,17.546 C5.4916,16.6452 5.11344,15.60488 5.11344,15.60488 C5.58208,15.91776 5.588,15.88432 5.89768,16.14024 C7.20768,17.22296 7.73144,18.30648 7.90832,18.76728 C7.91424,18.76368 7.92032,18.76016 7.92672,18.75664 C7.70328,17.74808 7.07344,13.81784 10.22888,11.7528 C10.51984,11.56264 11.19152,11.18432 11.19152,11.18432 C11.19152,11.18432 9.40464,15.09152 13.33856,18.2652 C13.25072,16.96632 13.74624,15.76704 14.67352,14.84296 C15.17728,14.34136 16.08512,14.10032 16.08512,14.10032 C16.08512,14.10032 15.83576,14.97376 15.79592,15.18 C15.27064,17.90344 17.55704,20.02112 17.06856,22.6008 C16.97696,23.08432 16.84576,23.5536 16.6628,23.99992 L16.8812,23.99992 C18.04088,23.38064 19.11712,22.57528 19.85416,21.47024 C21.5312,18.956 20.77752,15.72664 19.16296,13.38168 Z" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
