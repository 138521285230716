import React from 'react';
import QuoteLayout from '../../components/QuoteLayout/quote-layout';
import QuoteContext from '../../context/quote-context';
import Hob from '../../components/GetQuoteSteps/hob';

export default function QuoteHobPage() {
  return (
    <QuoteLayout>
      <QuoteContext.Consumer>
        {quote => (
          <Hob set={quote.set} hob={quote.hob} />
        )}
      </QuoteContext.Consumer>
    </QuoteLayout>
  );
}
