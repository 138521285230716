import React from 'react';

export default function Spiral({ color }) {
  return(
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>spiral</title>
      <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Contact-Hob" transform="translate(-148.000000, -204.000000)" fill={color} fillRule="nonzero">
          <g id="electric" transform="translate(20.000000, 190.000000)">
            <g id="Group" transform="translate(0.000000, 14.000000)">
              <g id="spiral" transform="translate(128.000000, 0.000000)">
                <path d="M12.75,24 C5.71948242,24 -8.70414851e-14,18.2805176 -8.70414851e-14,11.25 C-8.70414851e-14,5.04675291 5.04675291,0 11.25,0 C17.4532471,0 22.5,5.04675291 22.5,11.25 C22.5,16.6259766 18.1259766,21 12.75,21 C7.37402344,21 3,16.6259766 3,11.25 C3,6.70129397 6.70129397,3 11.25,3 C15.798706,3 19.5,6.70129397 19.5,11.25 C19.5,14.972168 16.4714355,18 12.75,18 C9.02856445,18 6,14.972168 6,11.25 C6,8.35583494 8.35491942,6 11.25,6 C14.1450806,6 16.5,8.35583494 16.5,11.25 C16.5,13.31781 14.81781,15 12.75,15 C10.68219,15 9,13.31781 9,11.25 C9,10.0094604 10.0094604,9 11.25,9 C12.4905396,9 13.5,10.0094604 13.5,11.25 L13.5,12 L12,12 L12,11.25 C12,10.8359985 11.663269,10.5 11.25,10.5 C10.836731,10.5 10.5,10.8359985 10.5,11.25 C10.5,12.4905396 11.5094604,13.5 12.75,13.5 C13.9905396,13.5 15,12.4905396 15,11.25 C15,9.18218995 13.31781,7.5 11.25,7.5 C9.18218995,7.5 7.5,9.18218995 7.5,11.25 C7.5,14.1441651 9.85491942,16.5 12.75,16.5 C15.6450806,16.5 18,14.1441651 18,11.25 C18,7.52783203 14.9714355,4.5 11.25,4.5 C7.52856445,4.5 4.5,7.52783203 4.5,11.25 C4.5,15.798706 8.20129397,19.5 12.75,19.5 C17.298706,19.5 21,15.798706 21,11.25 C21,5.87402344 16.6259766,1.5 11.25,1.5 C5.87402344,1.5 1.5,5.87402344 1.5,11.25 C1.5,17.4532471 6.54675291,22.5 12.75,22.5 C16.753418,22.5 20.4876709,20.3444824 22.4954224,16.8742676 L23.7938232,17.6257324 C21.5189209,21.55719 17.2875366,24 12.75,24 Z" id="Path"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
