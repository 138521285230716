import React from 'react';

export default function Flash({ color }) {
  return(
    <svg width="19px" height="24px" viewBox="0 0 19 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>flash</title>
      <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Contact-Hob" transform="translate(-151.000000, -305.000000)" fill={color} fillRule="nonzero">
          <g id="induction" transform="translate(20.000000, 292.000000)">
            <g id="Group-2" transform="translate(0.000000, 13.000000)">
              <g id="flash" transform="translate(131.000000, 0.000000)">
                <polygon id="Path" points="18.1463437 7.58451562 9.92264062 7.58451562 14.2938281 0 4.40751562 0 0.04115625 12.4782187 7.0606875 12.4782187 3.94917187 24"></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
