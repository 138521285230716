import React from 'react';
import { useIntl } from 'react-intl';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import FormContainer from '../Form/form-container';
import FormHeadline from '../FormHeadline/form-headline';
import CheckBox from '../Form/checkbox';
import Spiral from '../Icons/spiral';
import Flash from '../Icons/flash';
import Flame from '../Icons/flame';

export default function Hob({ set, hob }) {
  const intl = useIntl();
  const nextStepPath = '/get-quote/hob-count';

  return(
    <FormContainer>
      <FormHeadline>{intl.formatMessage({ id: "quoteHobHeadline" })}</FormHeadline>
      <Link to={nextStepPath}>
        <CheckBox checked={hob === 'electric'} handleClick={() => set({hob: 'electric'})}>
          <div>
            <Spiral color={'#333'} />
            <div>{intl.formatMessage({ id: "quoteHobElectric" })}</div>
          </div>
        </CheckBox>
      </Link>

      <Link to={nextStepPath}>
        <CheckBox checked={hob === 'induction'} handleClick={() => set({hob: 'induction'})}>
          <div>
            <Flash color={'#333'} />
            <div>{intl.formatMessage({ id: "quoteHobInduction" })}</div>
          </div>
        </CheckBox>
      </Link>

      <Link to={nextStepPath}>
        <CheckBox checked={hob === 'gas'} handleClick={() => set({hob: 'gas'})}>
          <div>
            <Flame color={'#333'} />
            <div>{intl.formatMessage({ id: "quoteHobGas" })}</div>
          </div>
        </CheckBox>
      </Link>
    </FormContainer>
  );
}
